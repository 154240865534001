import { IMedication } from './medlogic/imedication';
import { IVitalSign } from './ivital-sign';

export interface IPatient {
  nomeHospede: string;
  nascimento: Date;
  outraReligiosidade: string;
  nomemae: string;
  codigo: any;
  titulo: string;
  habilitado: boolean;
  sexo: string;
  estadoCivil: string;
  estado: string;
  cor: string;
  aposentado: boolean;
  religiao: string;
  data: Date;
  numeroCMCCentralMarcacaoConsulta: string;
  cNS: string;
  profissao: string;
  idade: number;
  naturalidade: string;
  paisNascimento: string;
  nomeSocial: string;
  cPF: string;
  rG: string;
  anosescolaridade: string;
  enfermeiroReferenciaUBS: string;
  medicoReferencia: string;
  centroSaude: string;
  regional: string;
  telefone: string;
  planoSaude: string;
  sistemasaudequeEstaVinculado: boolean;
  iDInicio: any;
  sistemasaudeVinculado: string;
  descricao: string;
  classificacao: string;
  identificacao1: string;
  identificacao2: string;
  sAME: string;
  especialidade: string;
  fisioterapeuta: string;
  psicologo: string;
  temAlgumaAlergiamaiorGravidade: boolean;
  seSimQual: string;
  temAlgumaDeficienciaVisualAuditivaFisicaOuOutra: boolean;
  seSimQual2: string;
  nomepai: string;
  situacaoPaciente: boolean;
  pacienteFalecido: boolean;
  dataObito: Date;
  anoObito: number;
  usaFralda: boolean;
  comeSozinho: boolean;
  cadeirante: boolean;
  traqueostomizado: boolean;
  ulceradecubito: boolean;
  sondaGastrica: boolean;
  sondaNaso: boolean;
  comUlceraPosHospitalizacao: boolean;
  hospital: string;
  fraturaColofemur: string;
  diabetico: boolean;
  usaInsulina: boolean;
  menores60: any;
  dataAdmissao: Date;
  anoAdmissao: number;
  admissaoAntes2000: boolean;
  hospitalSim: boolean;
  agendamento: any;
  unidade: string;
  urlLogo: string;
  prontuarioSoma: any;
  filtro: any;
  totalCadastro: any;
  prontuarioFinalCalc: any;
  pRTO: any;
  identificacoes: string;
  endereco: string;
  numero: string;
  bairro: string;
  cidade: string;
  telefone2: string;
  estado2: string;
  celular: string;
  eMail: string;
  quarto: string;
  regiao: string;
  trato: string;
  educacao: string;
  foto: string;
  referencia: string;
  perfilCliente: string;
  informacoesImportante: string;
  diagnostico: string;
  pesoAtual: any;
  altura: any;
  imc: any;
  medications: IMedication[];
  lstIntervecoesMedicamentos: any[];
  lstIntervencoesVitalSigns?: IVitalSign[];
  calcMedicationsCount: number;
  prontuario: string;
  calcHasOccurrence: boolean; // Indica se houve intercorrência ou alteração em sinais vitais. Deve ser populado, NÃO é preenchido automaticamente.
  calcHasEvolutionOccurrence: boolean; // Indica se houve intercorrência. Deve ser populado, NÃO é preenchido automaticamente.
  calcHasVitalSignOccurrence: boolean; // Indica se há sinais vitais fora da referência.
  calcCodigo(thisPatient: IPatient): number; // É o código calculado. Há inconsistência na codificação, hora no prontuário, hora no código, hora apenas na identificacao2 esse campo calculado visa corrigir isso.
  // TODO: Nomenclaturas do IDOSO.MEDLOGIC necessário padronizar no app e demais ambientes
  situacaoHospede: boolean;
  nomeResidente: string;
}
