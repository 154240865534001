import { IInterventionMedication, IMedication, IPatient } from '@medlogic/shared/shared-interfaces';
import { EntityState } from '@ngrx/entity';

export interface IMedicationState extends EntityState<IMedication> {
    // additional entities state properties
    error: any;
    selectedId: number | null;
    numOfDaysToCheckOcurrency: number;
    confirmItem: { medication: IMedication, patient: IPatient, observation: string, updateStock: boolean };
    isSaving: boolean;
    rawMedications: IMedication[]; // Para carregamento da lista bruta de medicamentos.
    historyMedications: IMedication[]; // Para carregamento do histórico anonimizado de prescrições.
    prescriptionId: number; // Para carregamentos dos medicamentos de uma prescrição específica.
    selectedMedication: IInterventionMedication;
    allMedications: IInterventionMedication[];
    isLoading: boolean;
    keyword: string | undefined;
}
