import { EnMedicationPeriod } from './../enum/en-medication-period.enum';
import { IMedication } from '@medlogic/shared/shared-interfaces';

export interface IMedicationPeriod {
    startHour?: number;
    endHour?: number;
    title: string;
    medications?: IMedication[];
    filter?: Function;
    styleCss: string;
    enMedicationPeriod: EnMedicationPeriod;
}
