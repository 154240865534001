export interface IGetPatientsWithMedicationsRequest {
    cadPacienteNo: number;
    cadEvolutionNo: number;
    cadIntervecoesMedicamentosNo: number;
    cadCheckMedicamentosNo: number;
    cadIntervecoesHorariosNo: number;
    cadVitalSignsNo: number;
    cadAutorizacaoCuidadoNo: number;
    tenantId: number;
    dtStart: string;
    dtEnd: string;
    numOfDaysToCheckOcurrency: number;
}
