// Module
export * from './lib/shared-interfaces.ng-module';

// Interfaces
export * from './lib/interface/ibasic';
export * from './lib/interface/icadastro';
export * from './lib/interface/icliente';
export * from './lib/interface/iconta';
export * from './lib/interface/icustomer';
export * from './lib/interface/idado';
export * from './lib/interface/idepartamento';
export * from './lib/interface/iform';
export * from './lib/interface/iget-form-by-name';
export * from './lib/interface/iget-form';
export * from './lib/interface/ilogin-with-user';
export * from './lib/interface/iget-label-value-combobox-by-form';
export * from './lib/interface/iget-subprocess-by-user';
export * from './lib/interface/iget-getrelationships-by-post';
export * from './lib/interface/ilist-item';
export * from './lib/interface/ipapel';
export * from './lib/interface/iqueue-item';
export * from './lib/interface/iservice-provider';
export * from './lib/interface/ityped-value';
export * from './lib/interface/iuser-exist';
export * from './lib/interface/iusuario';
export * from './lib/interface/iatividade-componente-dal';
export * from './lib/interface/ikey-value';
export * from './lib/interface/iconfig-modules';
export * from './lib/interface/iocorrencia';
export * from './lib/interface/idialog-data';
export * from './lib/interface/ipatient';
export * from './lib/interface/ivital-sign';
export * from './lib/interface/imodule';
export * from './lib/interface/imodule-info';
export * from './lib/interface/iroute';
export * from './lib/interface/icadastro-lista-dal';
export * from './lib/interface/iprocess';
export * from './lib/interface/iapp-state';
export * from './lib/interface/iapp-addhere-state';
export * from './lib/interface/ischool-state';
export * from './lib/interface/irouter-state';
export * from './lib/interface/itenant-state';
export * from './lib/interface/iprocess-state';
export * from './lib/interface/igeneric-state';
export * from './lib/interface/itenant';
export * from './lib/interface/icad-param';
export * from './lib/interface/iperson-state';
export * from './lib/interface/iperson';
export * from './lib/interface/iclassroom';
export * from './lib/interface/iprofessional';
export * from './lib/interface/ischool';
export * from './lib/interface/ifavorite';
export * from './lib/interface/igeneric';
export * from './lib/interface/isnapiv';
export * from './lib/interface/isnapiv-state';
export * from './lib/interface/idocumento';
export * from './lib/interface/icheck-user';
export * from './lib/interface/inewapi-user-role';
export * from './lib/interface/inewapi-login';
export * from './lib/interface/ilogin';
export * from './lib/interface/ilogin-state';
export * from './lib/interface/ichart';
export * from './lib/interface/iaudio';
export * from './lib/interface/id3data';
export * from './lib/interface/icolumn';
export * from './lib/interface/itarefa-componente';
export * from './lib/interface/iurl';
export * from './lib/interface/ismart-search';
export * from './lib/interface/iqueue';
export * from './lib/interface/iqueue-state';
export * from './lib/interface/ilog-state';
export * from './lib/interface/icost-center-state';
export * from './lib/interface/iutils-email-state';
export * from './lib/interface/ilog';
export * from './lib/interface/ivideo';
export * from './lib/interface/iemail-message';
export * from './lib/interface/medlogic/isaving-status';

// API interfaces
export * from './lib/interface/api/iemail';
export * from './lib/interface/api/igenerate-token';
export * from './lib/interface/api/ivalidate-token-result';
export * from './lib/interface/api/ivalidate-token';
export * from './lib/interface/api/ifile-upload-request';
export * from './lib/interface/api/ifile-upload-response';
export * from './lib/interface/api/ijwt-auth-request';
export * from './lib/interface/api/iattachment-headers-response';
export * from './lib/interface/api/iattachment-headers-request';
export * from './lib/interface/api/ifile-delete-request';
export * from './lib/interface/api/ifile-delete-response';
export * from './lib/interface/api/ischool-user';
export * from './lib/interface/api/iget-patients-with-medications-request';
export * from './lib/interface/api/iget-redis-request';
export * from './lib/interface/api/iget-ivcf-request';
export * from './lib/interface/api/iget-authorized-patients-request';
export * from './lib/interface/api/ipayload';
export * from './lib/interface/api/iget-medication-count-for-patients-request';
export * from './lib/interface/api/isend-email-response';
export * from './lib/interface/api/ipost-check-medication-stock-request';
export * from './lib/interface/api/ipost-check-medication-stock-response';
export * from './lib/interface/api/idelete-redis-invalidate-cache';
export * from './lib/interface/api/ipost-medication-checkin-request';

// ADDHERE
export * from './lib/interface/addhere/ievolution';
export * from './lib/interface/addhere/ievolution-state';
export * from './lib/interface/addhere/ieducator';
export * from './lib/interface/addhere/ieducator-state';
export * from './lib/interface/addhere/iresponsible';
export * from './lib/interface/addhere/irelationship';
export * from './lib/interface/addhere/iperson-relationship';
export * from './lib/interface/addhere/iresponsible-state';
export * from './lib/interface/addhere/iprofessional-state';
export * from './lib/interface/addhere/iperson-educator';
export * from './lib/interface/addhere/iperson-professional';
export * from './lib/interface/addhere/iperson-responsible';
export * from './lib/interface/addhere/iperson-responsible-state';
export * from './lib/interface/addhere/iperson-professional-state';
export * from './lib/interface/addhere/iperson-educator-state';
export * from './lib/interface/addhere/ischool-user-state';
export * from './lib/interface/addhere/irpt-screening-tdah-tod';

export * from './lib/interface/addhere/ipost';
export * from './lib/interface/addhere/ipost-state';
export * from './lib/interface/addhere/icomment';
export * from './lib/interface/addhere/icomment-state';
export * from './lib/interface/addhere/iview';
export * from './lib/interface/addhere/iview-state';
export * from './lib/interface/addhere/ipostview';
export * from './lib/interface/addhere/ipostcomment';
export * from './lib/interface/addhere/ipost-comment-state';
export * from './lib/interface/addhere/ipost-view-state';
export * from './lib/interface/addhere/iget-by-school-request';
export * from './lib/interface/addhere/iget-person-authorized-request';

// MedLogic
export * from './lib/interface/medlogic/imedication';
export * from './lib/interface/medlogic/iestoque-materiais';
export * from './lib/interface/medlogic/ihistorico-compra-estoque';
export * from './lib/interface/medlogic/imedication-checkin';
export * from './lib/interface/medlogic/iintervencoes-medicamentos-horarios';
export * from './lib/interface/medlogic/iintervention-medication';
export * from './lib/interface/medlogic/imedlogic-evolution';
export * from './lib/interface/medlogic/imedlogic-professional';
export * from './lib/interface/medlogic/istock-change';
export * from './lib/interface/medlogic/iivcf';
export * from './lib/interface/medlogic/idisability';
export * from './lib/interface/medlogic/ivulnerability';
export * from './lib/interface/medlogic/iprescription-medication';
export * from './lib/interface/medlogic/imedication-grid'
export * from './lib/interface/medlogic/ihorarios'
export * from './lib/interface/input-mask-type';
export * from './lib/interface/medlogic/imedication-list';
export * from './lib/interface/medlogic/ibusiness-unit';
export * from './lib/interface/medlogic/icost-center';
export * from './lib/interface/medlogic/ipresentation';
export * from './lib/interface/medlogic/iautorizacao-cuidado';
export * from './lib/interface/medlogic/ifamily';
export * from './lib/interface/medlogic/iemployee';
export * from './lib/interface/medlogic/iposology';
export * from './lib/interface/medlogic/itherapeutic-safe';

// UI
export * from './lib/interface/ui/ismart-list';
export * from './lib/interface/ui/imenu-item';
export * from './lib/interface/ui/isplash';
export * from './lib/interface/ui/ilettering';
export * from './lib/interface/ui/icontact';

// Services
export * from './lib/service/log.service';
export * from './lib/service/global.service';
export * from './lib/service/unsubscribe-ondestroy.adapter';
export * from './lib/service/lib.service';
export * from './lib/service/error';
export * from './lib/service/tap-console';
export * from './lib/service/msg-ptBR.service';
export * from './lib/service/config-json.service';
export * from './lib/service/init-config';
export * from './lib/service/api-routes';

// Enum
export * from './lib/enum/en-color.enum';
export * from './lib/enum/en-content-type.enum';
export * from './lib/enum/en-customer-action.enum';
export * from './lib/enum/en-request-type.enum';
export * from './lib/enum/en-serial-msg.enum';
export * from './lib/enum/en-theme.enum';
export * from './lib/enum/en-typed-value.enum';
export * from './lib/enum/en-user-role.enum';
export * from './lib/enum/en-validation-state.enum';
export * from './lib/enum/en-view-mode.enum';
export * from './lib/enum/enum-ctr-type.enum';
export * from './lib/enum/enum-tipo-lista.enum';
export * from './lib/enum/en-components-view';
export * from './lib/enum/en-modulo.enum';
export * from './lib/enum/en-agrupamento-processos';
export * from './lib/enum/en-login-state.enum';
export * from './lib/enum/en-ws-track-status.enum';
export * from './lib/enum/en-module-mode.enum';
export * from './lib/enum/en-expiration-type';
export * from './lib/enum/en-material-icon.enum';
export * from './lib/enum/en-rpt-type.enum';
export * from './lib/enum/en-invite-status.enum';
export * from './lib/enum/en-type-person.enum';
export * from './lib/enum/en-sort-by.enum';
export * from './lib/enum/en-paper-size.enum';
export * from './lib/enum/en-paper-orientation.enum';
export * from './lib/enum/en-ordem-processo.enum';
export * from './lib/enum/en-video-type.enum';
export * from './lib/enum/en-email-message-status.enum';
export * from './lib/enum/en-prescription-view-mode.enum';
// ENUM Medlogic
export * from './lib/enum/medlogic/en-stock-status.enum';
export * from './lib/enum/medlogic/en-stock-status.enum';
export * from './lib/enum/medlogic/en-posology.enum';
export * from './lib/enum/medlogic/en-stock-change-mode.enum';
export * from './lib/enum/medlogic/en-stock-change-mode.enum';
export * from './lib/enum/medlogic/en-stock-change.enum';
export * from './lib/enum/medlogic/en-stock-change.enum';
export * from './lib/enum/medlogic/en-ivcf-disability.enum';

// json
// export * as config from './assets/config.json';

// Map
export * from './lib/service/map/map-intervention-medication';
export * from './lib/service/map/map-posologia';
export * from './lib/service/map/map-apresentacao';
export * from './lib/service/map/map-prescription-medication';
export * from './lib/service/map/map-therapeutic-safe';
export * from './lib/service/map/map-medication-checkin';
