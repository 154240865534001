export interface IGetFormRequest {
    token?: string;
    formId: number;
    listVariables: string;
    filter: string;
    isFilterAnd: boolean;
    DtInicio: string;
    DtFim: string;
}

export interface IGetFormResponse {
    CadastroNo: number;
    OcorrenciaNo: number;
    Titulo: string;
    // Atenção: haverá várias chaves adicionais correspondentes às variáveis do tipo V_387: value
    [key: string]: any;
}

export interface IGetDataRegisterRequest {
  token?: string;
  OcorrenciaNo?: number;
  CadastroNo: number;
  LstVariaveisRetorno: string;
  VariavelGrid: string;
  LstVariaveisGridRetorno: string;
  filter: string;
  DtInicio: string;
  DtFim: string;
  FiltroExtra: string;
  LstVariaveisMapa: string;
  VariavelMapa: string;
}

export interface IGetDataRegisterResponse {
  CadastroNo: number;
  OcorrenciaNo: number;
  Titulo: string;
  // Atenção: haverá várias chaves adicionais correspondentes às variáveis do tipo V_387: value
  [key: string]: any;
}


export interface IGetMedLogic {
  CadastroNo: number;
  stockItemId: number; // StockItem
  quantity: number;
  lstStockVariables: string;
  lstPurchaseHistoryVariables: string;
  batch: string;
  description: string;
}



export interface IGetMedlogicResponse {
 response: boolean;
}


